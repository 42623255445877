<template>
  <div class="dropbox">
    <input
      type="file"
      :multiple="allowMultipleUploads"
      class="input-file"
      title=" "
      @change="filesChange($event)"
    />
    <v-row justify="center" align="center" class="py-4">
      <v-icon color="primary" size="96"> 
        mdi-cloud-upload-outline 
      </v-icon>
    </v-row>
    <p class="pb-4 text-center display-2">
      Clique para adicionar ou arraste e solte
    </p>
  </div>
</template>

<script>

import randomString from '@/utils/generateRandomString.js';

export default {
  name: 'UploadArea',
  props: {
    allowMultipleUploads: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    filesChange(event) {

      const fileList = event.target.files;

      if (!fileList.length) return;

      for (const file of fileList) {
        this.$emit('files:change', {
          id: randomString(30),
          name: file.name,
          size: file.size,
          fileObj: file,
        });
      }

      event.target.value = '';
    },
  },
};
</script>

<style lang="scss">

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -3px;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}

.input-file {
  z-index: 111;
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

</style>
